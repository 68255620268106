<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-18 09:19:52
 * @LastEditTime: 2021-05-14 13:32:54
 * @FilePath: /shop_frontend/src/views/memberList/index.vue
-->
<template>
  <div class="page">
    <div class="formBox">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="手机号">
              <a-input
                placeholder="搜索手机号"
                style="width: 220px"
                v-model="form.phone"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="微信昵称">
              <a-input
                placeholder="微信昵称"
                style="width: 220px"
                v-model="form.name"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="会员卡">
              <a-select
                style="width: 220px"
                placeholder="请选择会员卡"
                v-model="form.card"
                allowClear
              >
                <a-select-option
                  :value="el.id"
                  v-for="(el, index) in cardList"
                  :key="index"
                >
                  {{ el.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-item label="注册时间">
              <a-range-picker
                allowClear
                v-model="searchDate"
                valueFormat="YYYY-MM-DD"
              >
              </a-range-picker>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="6">
            <a-form-item label="邀请人">
              <a-input placeholder="收货人手机号" style="width: 220px" />
            </a-form-item>
          </a-col> -->
          <!-- <a-col :span="10">
            <a-form-item style="text-align: left">
              <a-checkbox v-model="form.has_phone">有手机号</a-checkbox>
              <a-checkbox v-model="form.has_points">有积分</a-checkbox>
              <a-checkbox v-model="form.has_balance">有余额</a-checkbox>
              <a-checkbox v-model="form.has_card">有会员卡</a-checkbox>
            </a-form-item>
          </a-col> -->
                   <a-col :span="8">
            <a-form-item label="邀请人">
              <a-input
                placeholder="邀请人"
                style="width: 220px"
                v-model="form.inviter_name"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-button
                type="primary"
                @click="handleSearch"
                style="margin-right: 10px"
              >
                筛选
              </a-button>
              <!-- <a-button type=""> 会员导出 </a-button> -->
            </a-form-item>
          </a-col>
        </a-row>

        <!-- <a-row>
          <a-col :span="6" :offset="10">
            <a-form-item>
              <a-button type="link"> 更多 </a-button>
            </a-form-item>
          </a-col>
        </a-row> -->
      </a-form>
    </div>
    <div class="page__table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        bordered
        @change="changeTable"
      >
      <div slot="name" slot-scope="text, record">
          <span style="color: #1890ff; cursor: pointer;" @click="openDetail(record)">{{ record.name }}</span>
        </div>
        <div slot="sharer_name" slot-scope="text, record">
          <span>{{ record.sharer_name }}</span>
          <a-icon
            type="edit"
            style="color: #40a9ff; font-size: 16px; cursor: pointer"
            @click="addUser(record)"
          />
        </div>
        <div slot="balance" slot-scope="text, record" style="text-align: center;">
          <span>{{ record.balance }}</span>
          <a-icon
            type="plus-circle"
            style="
              color: #40a9ff;
              font-size: 16px;
              cursor: pointer;
              margin:0 8px;
            "
            @click="addBalance(record)"
          />
        </div>
        <div slot="points" slot-scope="text, record" style="text-align: center;">
          <span>{{ record.points }}</span>
          <a-icon
            type="plus-circle"
            style="
              color: #40a9ff;
              font-size: 16px;
              cursor: pointer;
              margin:0 8px;
            "
            @click="addPoints(record)"
          />
        </div>
        <div slot="card_name" slot-scope="text, record">
          <span>{{ record.card_name }}</span>
          <a-button type="link" @click="openCard(record)">设置</a-button>
        </div>
        <div slot="remark" slot-scope="text, record">
          <span>{{ record.remark }}</span>
          <a-icon
            type="edit"
            style="color: #40a9ff; font-size: 16px; cursor: pointer"
            @click="addRemark(record)"
          />
        </div>
        <span slot="action" slot-scope="text, record">
          <div class="action_custom">
            <a-button type="link" @click="openBalance(record)"
              >余额明细</a-button
            >
            <a-button type="link" @click="openPoints(record)"
              >积分明细</a-button
            >
            <a-button type="link" @click="toggleActive(record)">{{
              record.is_active ? "冻结" : "激活"
            }}</a-button>
            <a-button type="link" @click="openOrderList(record)"
              >订单列表</a-button
            >
            <!-- <a-button type="link" @click="openTag(record)">标签选择</a-button> -->
            <a-button type="link" @click="openDetail(record)"
              >查看详情</a-button
            >
            <a-button type="link" style="color: red;" @click="deleteDetail(record)"
              >删除</a-button
            >
          </div>
        </span>
      </a-table>
    </div>
    <a-modal
      title="修改金额"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
      width="50%"
    >
      <div>
        <a-row style="margin-bottom: 20px" type="flex" align="middle">
          <a-col span="4">当前金额</a-col>
          <a-col span="19" offset="1">{{ currentRecord.balance || 0 }}</a-col>
        </a-row>
        <a-row style="margin-bottom: 20px" type="flex" align="middle">
          <a-col span="4">增加金额</a-col>
          <a-col span="19" offset="1">
            <a-input class="w220" v-model="addForm.num"></a-input>
            <span>(*当余额为正时，增加余额；余额为负时，减少余额*)</span>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle">
          <a-col span="4">增加备注</a-col>
          <a-col span="19" offset="1">
            <a-input v-model="addForm.remark"></a-input>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <add-points
      :visible="pointsVisible"
      :currentRecord="currentRecord"
    ></add-points>
    <a-modal
      title="修改备注"
      :visible="remarkVisible"
      @ok="handleOkRemark"
      @cancel="remarkVisible = false"
      width="50%"
    >
      <div>
        <a-row type="flex" align="middle">
          <a-col span="4">备注</a-col>
          <a-col span="19" offset="1">
            <a-input v-model="remarkInfo.remark"></a-input>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <a-modal
      title="修改会员卡"
      :visible="cardVisible"
      @ok="handleOkCard"
      @cancel="cardVisible = false"
      width="50%"
    >
      <div>
        <a-row type="flex" align="middle">
          <a-col span="4">会员卡</a-col>
          <a-col span="19" offset="1">
            <a-select style="width: 220px" v-model="cardInfo.card">
              <a-select-option
                :value="el.id"
                v-for="(el, index) in cardList"
                :key="index"
              >
                {{ el.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <add-user :visible="userVisible" :currentRecord="currentRecord"></add-user>
    <a-modal
      title="修改标签"
      :visible="tagVisible"
      @ok="handleOkTag"
      @cancel="tagVisible = false"
      width="50%"
    >
      <div>
        <a-row type="flex" align="middle">
          <a-col span="4">标签</a-col>
          <a-col span="19" offset="1">
            <a-select style="width: 220px" v-model="tagInfo.label">
              <a-select-option
                :value="el.id"
                v-for="(el, index) in tagList"
                :key="index"
              >
                {{ el.name }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "number",
     align: 'center'
  },
  {
    title: "昵称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
     align: 'center'
  },
  {
    title: "手机号",
    dataIndex: "phone",
     align: 'center'
  },
  {
    title: "邀请人",
    dataIndex: "sharer_name",
    key: "sharer_name",
    scopedSlots: { customRender: "sharer_name" },
     align: 'center'
  },
  {
    title: "余额",
    dataIndex: "balance",
    key: "balance",
    scopedSlots: { customRender: "balance" },
     align: 'center'
  },
  {
    title: "积分",
    dataIndex: "points",
    key: "points",
    scopedSlots: { customRender: "points" },
     align: 'center'
  },
  {
    title: "会员卡",
    dataIndex: "card_name",
    key: "card_name",
    scopedSlots: { customRender: "card_name" },
     align: 'center'
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
    scopedSlots: { customRender: "remark" },
     align: 'center'
  },
  {
    title: "注册时间",
    dataIndex: "create_time",
    key: "create_time",
     align: 'center'
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 300,
  },
];
export default {
  name: "MemberList",
  components: {
    addPoints: () => import("./addPoints"),
    addUser: () => import("./addUser"),
  },
  data() {
    return {
      tagVisible: false,
      userVisible: false,

      cardVisible: false,
      pointsVisible: false,
      remarkVisible: false,
      addForm: {
        num: "",
        remark: "",
      },
      visible: false,
      dataSource: [],
      columns,
      page: 1,
      cardList: [],
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      form: {
        inviter_name: ''
      },
      searchDate: [],
      currentRecord: {},
      remarkInfo: {
        remark: "",
      },
      cardInfo: {
        card: "",
      },
      tagInfo: {
        label: "",
      },
      tagList: []
    };
  },
  created() {
    let info = this.$route.query.info;
    if (info) {
      info =JSON.parse(info);
      this.form.card = info.id
    }
    this.initTable();
    this.initCard();
    this.getTag();
  },
  computed: {
    params() {
      return {
        page: this.page,
        ...this.form,
        start_date: this.searchDate[0] || "",
        end_date: this.searchDate[1] || "",
      };
    },
  },
  methods: {
    deleteDetail (record) {
      let _this = this;
      this.$confirm({
        title: "是否删除该记录?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          console.log("OK");
          _this.$axios.delete(`/members/${record.id}/`).then((res) => {
            _this.initTable();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    openDetail (obj) {
      this.$router.push({
        name: 'memberDetail',
        query: {
          info: JSON.stringify(obj)
        }
      })
    },
    openOrderList (obj) {
      this.$router.push({
        name: 'orderList',
        query: {
          info: JSON.stringify(obj)
        }
      })
    },
    openPoints (obj) {
      this.$router.push({
        name: 'memberPointsDetail',
        query: {
          info: JSON.stringify(obj)
        }
      })
    },
    openBalance (obj) {
      this.$router.push({
        name: 'memberBalanceDetail',
        query: {
          info: JSON.stringify(obj)
        }
      })
    },
    handleOkTag() {
      this.$axios
        .post(`/members/${this.currentRecord.id}/set_label/`, {
          label: this.tagInfo.label,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.tagVisible = false;
          this.initTable();
        });
    },
    getTag () {
      this.$axios.get("/labels/", { params: {
        page: 1, page_size: 9999
      } }).then((res) => {
        this.tagList = res.results || [];
      });
    },
    handleOkCard() {
      if (!this.cardInfo.card) {
        this.$message.error('请选择会员卡')
        return
      }
      this.$axios
        .post(`/members/${this.currentRecord.id}/set_card/`, {
          card: this.cardInfo.card,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.cardVisible = false;
          this.initTable();
        });
    },
    openCard(obj) {
      this.currentRecord = obj;
      this.cardVisible = true;
      this.cardInfo = {
        card: "",
      };
    },
    openTag(obj) {
      this.currentRecord = obj;
      this.tagVisible = true;
      this.tagInfo = {
        label: obj.label,
      };
    },
    addUser(obj) {
      this.currentRecord = obj;
      this.userVisible = true;
    },
    toggleActive(obj) {
      let url = obj.is_active
        ? `/members/${obj.id}/frozen/`
        : `/members/${obj.id}/activate/`;
      this.$axios.post(url).then((res) => {
        this.$message.success("操作成功");
        this.initTable();
      });
    },
    handleOkRemark() {
      this.$axios
        .post(`/members/${this.currentRecord.id}/set_remark/`, {
          remark: this.remarkInfo.remark,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.remarkVisible = false;
          this.initTable();
        });
    },
    handleOk() {
      this.$axios
        .post(`/members/${this.currentRecord.id}/add_balance/`, {
          amount: this.addForm.num,
          remark: this.addForm.remark,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.visible = false;
          this.initTable();
        });
    },
    addBalance(obj) {
      this.currentRecord = obj;
      this.visible = true;
      this.addForm = {
        num: "",
        remark: "",
      };
    },
    addRemark(obj) {
      this.currentRecord = obj;
      this.remarkVisible = true;
      this.remarkInfo = {
        remark: obj.remark || "",
      };
    },
    addPoints(obj) {
      this.currentRecord = obj;
      this.pointsVisible = true;
    },
    handleSearch() {
      this.page = 1;
      this.initTable();
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/members/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    initCard() {
      this.$axios
        .get("/cards/", { params: { page: 1, page_size: 9999 } })
        .then((res) => {
          this.cardList = res.results || [];
        });
    },
  },
};
</script>
<style lang="less" scoped>
.search__price {
  display: flex;
  align-items: center;
  > span {
    margin: 0 10px;
  }
}
</style>

